<template>
  <div class="create-email">
    <div class="container container--vsl">
      <div class="create-email__header">
        <router-link to="/">
          <h2>Mental Future</h2>
        </router-link>
      </div>
      <div class="create-email__body">
        <Form
          class="create-email__form"
          @submit="onSubmit"
          :validation-schema="schema"
        >
          <img src="@/assets/img/create-head.svg" />
          <h3 class="create-email__form-title">Mental Health App</h3>
          <div class="create-email__form-subtitle">
            {{
              hasRegistered
                ? 'Get Your Plan'
                : 'Where should we send your plan?'
            }}
          </div>
          <p class="create-email__form-desc">
            {{
              hasRegistered
                ? 'We just sent you an email. Pls click the link in your email to get your personal plan'
                : 'We won\’t share your personal data with anyone. We hate spam as much as you do!'
            }}
          </p>
          <div class="create-email__form-field" v-if="!hasRegistered">
            <Field
              class="default-input with-icon"
              placeholder="E-mail"
              type="email"
              name="email"
              v-model="form.email"
            />
            <ErrorMessage name="email" v-slot="{ message }">
              <p class="error-message">
                {{ message }}
              </p>
            </ErrorMessage>
          </div>
          <div
            class="registration__input-wrapper input-wrapper input-wrapper--sign-up"
            v-if="!hasRegistered"
          >
            <button type="submit">Sign Up</button>
          </div>
          <p class="create-email__form-info" v-if="!hasRegistered">
            By providing your email and phone number you consent to newsletters
            and marketing communication by Mental Future as described in our
            <router-link to="/terms-of-service" class="footer__menu-link">
              T&C
            </router-link>
            and
            <router-link to="privacy-policy" class="footer__menu-link">
              Privacy Policy </router-link
            >. We respect your privacy and do not tolerate spam. We will never
            share or sell your information to third parties.
          </p>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const hasRegistered = ref(false)

const schema = yup.object({
  email: yup.string().required().email(),
})

const form = {}

const onSubmit = async () => {
  const headers = {
    Accept: 'application/json',
  }

  const formData = { ...form, platform: 'web' }

  const data = await axios.post('user', formData, { headers })

  if (data) {
    if (data.status === 208) {
      hasRegistered.value = true
      return
    }
    store.commit('SET_TOKEN', data.token)
    localStorage.setItem('justRegistered', 1)
    if (route.query.isCustomQuiz === 'true') {
      router.push(`/quiz-dynamic-results/${route.query.quizId}`)
    } else {
      router.push('/quiz-results')
    }
  }
}
</script>

<style scoped lang="scss">
.create-email {
  padding: 36px 0;
  background: linear-gradient(341deg, #71b280 -33.77%, #134e5e 114.22%);
  min-height: 100vh;

  &__header {
    & h2 {
      font-family: var(--run);
      font-weight: 500;
      font-size: 36px;
      line-height: 36px;
      color: var(--color-white);
      margin-bottom: 0;
    }
  }
  &__body {
    min-height: calc(100vh - 108px);
    padding-top: 30px;
    display: flex;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    max-width: 327px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    & img {
      max-width: 170px;
      width: 100%;
      margin-bottom: 8px;
    }

    &-field {
      margin-bottom: 32px;
      width: 100%;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 40px;
      color: #fff;
    }

    &-subtitle {
      max-width: 250px;
      width: 100%;
      font-family: var(--run);
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      margin: 0 auto 24px;
    }

    &-desc {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 32px;
    }

    &-info {
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }

    & .input-wrapper {
      margin-top: auto;
    }
  }

  @media (max-width: 767px) {
    &__header {
      & h2 {
        font-size: 25px;
        margin: 0 auto;
      }
    }
  }
}
</style>
